<template>
  <div class="flex-form">
    <el-form
      ref="gameForm"
      v-loading="loading"
      :model="gameForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Game
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Game
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ gameUid }}
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="gameForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Visible"
        prop="visible"
      >
        <el-switch
          v-model="gameForm.isVisible"
        />
      </el-form-item>

      <el-form-item
        label="Title"
        prop="title"
      >
        <el-input v-model="gameForm.title" />
      </el-form-item>

      <el-form-item
        label="Position"
        prop="position"
      >
        <el-input v-model="gameForm.position" />
      </el-form-item>

      <el-form-item
        label="Icon"
      >
        <upload-media
          ref="iconImage"
          :image-url="iconUrl"
          :params="{ contentType: 'GAME_ICON' }"
          @success="handleIconUploadSuccess"
          @remove="handleIconUploadRemove"
        />
      </el-form-item>

      <el-form-item
        label="Logo"
      >
        <upload-media
          ref="logoImage"
          :image-url="logoUrl"
          :params="{ contentType: 'GAME_LOGO' }"
          @success="handleLogoUploadSuccess"
          @remove="handleLogoUploadRemove"
        />
      </el-form-item>

      <el-tabs
        v-model="activeLang"
        type="card"
        closable
        @edit="handleTabsEdit"
      >
        <el-tab-pane
          v-for="(item, index) in gameForm.content"
          :key="item.index"
          :label="langName(item.langCode)"
          :name="item.langCode"
        >
          <el-form-item
            :label="`Relative Url [${item.langCode}]`"
            prop="relativeUrl"
          >
            <RelativeUrl
              v-model="gameForm.content[index].relativeUrl"
              @generate-relative-url="handleRelativeUrl($event, index, gameForm.title)"
              @set-relative-url="setRelativeUrl($event, index)"
            />
          </el-form-item>

          <el-form-item
            :label="`Description [${item.langCode}]`"
            prop="description"
          >
            <Editor
              v-model="gameForm.content[index].description"
            />
          </el-form-item>

          <el-form-item
            :label="`Meta Title [${item.langCode}]`"
            prop="metaTitle"
          >
            <el-input v-model="gameForm.content[index].metaTitle" />
          </el-form-item>

          <el-form-item
            :label="`Meta Description [${item.langCode}]`"
            prop="metaDescription"
          >
            <el-input
              v-model="gameForm.content[index].metaDescription"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 8}"
            />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

      <!-- <el-form-item
        label="Logo"
      >
        <upload-media
          ref="uploadLogo"
          :image-url="logoUrl"
          @success="handleImageUploadSuccess"
        />
      </el-form-item> -->

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getGame,
  updateGame,
  createGame,
  generateRelativeUrl,
} from '@/api/game';
import { getAllLanguages } from '@/api/language';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import Editor from '@/components/Editor';
import RelativeUrl from '@/components/RelativeUrl';
import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'GameDetails',
  components: { UploadMedia, RelativeUrl, Editor },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    gameUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      gameForm: {
        title: '',
        isActive: true,
        isVisible: true,
        content: [],
        position: 0,
        logoMediaUid: null,
      },
      game: {},
      activeLang: '',
      langOptions: [],
      relativeUrlEdited: {},
      rules: {
        title: [{
          required: true,
          trigger: 'blur',
          message: 'Title is required',
        }],
        position: [{
          required: true,
          trigger: 'blur',
          message: 'Position is required',
        }],
      },
    };
  },
  computed: {
    iconUrl() {
      return this.gameForm?.media?.icon?.image?.location;
    },
    logoUrl() {
      return this.gameForm?.media?.logo?.image?.location;
    },
  },
  watch: {
    visible() {
      if (this.gameUid) {
        this.getGame(this.gameUid);
      } else {
        this.initForm();
      }
    },
  },
  async created() {
    if (this.isEditMode) {
      await this.getGame(this.gameUid);
    } else {
      await this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();

      await this.getLanguages();

      this.langOptions.forEach((item) => {
        this.gameForm.content.push({
          langCode: item.value,
          relativeUrl: '',
          description: '',
          metaTitle: '',
          metaDescription: '',
        });
      });

      this.activeLang = this.langOptions[0].value;
    },
    langName(code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const description = this.gameForm.content.find((item) => item.langCode === code)?.relativeUrl;

      if (!description.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getGame(gameUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getLanguages();

        const data = await getGame({ gameUid });

        this.game = cloneDeep(data.game);
        this.gameForm = data.game;

        // order content by langOptions
        this.gameForm.content = this.langOptions.map((item) => {
          const content = this.gameForm.content.find((c) => c.langCode === item.value);
          if (!content) {
            return {
              langCode: item.value,
              relativeUrl: '',
              description: '',
              metaTitle: '',
              metaDescription: '',
            };
          }

          return content;
        });

        this.activeLang = this.gameForm.content
          .find((item) => item.description.trim())?.langCode || this.langOptions[0].value;

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    async onEditSubmit() {
      this.$refs.gameForm.validate(async (valid) => {
        if (valid) {
          const updatedGame = makeUpdateObj(this.game, this.gameForm);
          if (Object.keys(updatedGame).length > 0) {
            try {
              this.saving = true;
              updatedGame.gameUid = this.gameUid;

              await updateGame(updatedGame);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');
            } catch (e) {
              // await this.getGame(this.gameUid);

              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.gameForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            await createGame(this.gameForm);

            this.$message({
              type: 'success',
              message: 'Game has been added successfully',
            });

            // this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.game = {};
      this.gameForm.content = [];
      this.gameForm.media = {};
      this.relativeUrlEdited = {};

      this.$nextTick(() => {
        this.$refs.gameForm.resetFields();
        this.$refs.logoImage.reset();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleTabsEdit(targetName) {
      // remove content
      const index = this.gameForm.content.findIndex((item) => item.langCode === targetName);

      this.gameForm.content[index].relativeUrl = '';
      this.gameForm.content[index].metaTitle = '';
      this.gameForm.content[index].metaDescription = '';
      this.gameForm.content[index].description = '';

      // this.removeStaticPageRules(index);
    },
    handleLogoUploadSuccess(data) {
      this.gameForm.logoMediaUid = data.mediaUid;
    },
    handleLogoUploadRemove() {
      this.gameForm.logoMediaUid = null;
      this.gameForm.media.logo = null;
    },
    handleIconUploadSuccess(data) {
      this.gameForm.iconMediaUid = data.mediaUid;
    },
    handleIconUploadRemove() {
      this.gameForm.iconMediaUid = null;
      this.gameForm.media.icon = null;
    },
    async handleRelativeUrl(editRelativeUrl, index, value) {
      if (this.gameUid && !editRelativeUrl) {
        return;
      }

      if (value && value.trim().length) {
        const data = await generateRelativeUrl({
          title: value,
          gameUid: this.gameUid,
          langCode: this.gameForm.content[index].langCode,
        });
        if (data.relativeUrl) {
          this.gameForm.content[index].relativeUrl = data.relativeUrl;
        }
      }
    },
    setRelativeUrl(value, index) {
      this.relativeUrlEdited[index] = true;
      this.handleRelativeUrl(true, index, value);
    },
  },
};
</script>
